import React, { Component } from 'react';
import SEO from "../components/seo"
import Imgix from 'react-imgix';

export default class Leduc99 extends Component {

    componentDidMount() {

        const pluginScript = document.createElement("script")
        pluginScript.src = "/js/leduc99/plugins.min.js"
        pluginScript.async = true

        document.body.appendChild(pluginScript)
    }

    render() {
        let page = this.props.pageContext.page
        return (
            <div>
                <SEO page={page} />
                <link rel="stylesheet" href="/leduc99/base.css"></link>
                <link rel="stylesheet" href="/leduc99/main.css"></link>
                <link rel="stylesheet" href="/leduc99/hotspot.css"></link>
                <link rel="stylesheet" href="/leduc99/vendor.css"></link>
                <link rel="stylesheet" href="/leduc99/responsive.css"></link>

                <div id="intro">

                    <section className="header-tfou">

                        <div className="col-two">
                            <a href="https://www.wd40.com"><Imgix height={250} width={250} src="/img/leduc99/logo-wd40.png" alt="WD-40" className="logo-header" htmlAttributes={{ alt: 'WD-40' }} /></a>
                        </div>

                        <div className="col-eight">

                            <h2 style={{ fontSize: "3.3vw" }}>
                                5x Pro4 off-road racing champion Kyle LeDuc’s
                            </h2>

                            <h1 style={{ fontSize: "6vw" }}>
                                4-Wheel Drive Ford Raptor
                            </h1>

                        </div>

                        <div className="col-two desktop">
                            <div className="item-wrap">
                                <a href="#modal-intro" className="hotspot overlay">
                                    <Imgix height={250} width={250} src="/img/leduc99/kyle-intro.png" alt="Kyle LeDuc Intro" htmlAttributes={{ alt: 'Kyle LeDuc Intro' }} />
                                </a>
                            </div>
                        </div>

                    </section>
                    <section>

                        <div className="row">

                            <div className="truck-container">

                                <Imgix sizes="500vw" src="/img/leduc99/main-truck.png" alt="4 Wheel Drive Ford Raptor"  htmlAttributes={{ alt: '4 Wheel Drive Ford Raptor' }} />

                                <div className="item-wrap">
                                    <a href="#modal-big-blaster" className="hotspot overlay" style={{ top: "20%", left: "97%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-carb-throttle" className="hotspot overlay" style={{ top: "17%", left: "75%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-dry-lube" className="hotspot overlay" style={{ top: "67%", left: "90%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-electrical-contact-cleaner" className="hotspot overlay" style={{ top: "16%", left: "89%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-ez-reach" className="hotspot overlay" style={{ top: "24%", left: "60%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-spray-and-stay-gel" className="hotspot overlay" style={{ top: "65%", left: "42%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-grease" className="hotspot overlay" style={{ top: "47%", left: "33%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-degreaser" className="hotspot overlay" style={{ top: "78%", left: "21%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                                <div className="item-wrap">
                                    <a href="#modal-mup" className="hotspot overlay" style={{ top: "38%", left: "71%" }}>
                                        <div className="btn-pulse">
                                            <span className="ring"></span>
                                            <span className="circle"></span>
                                        </div>
                                    </a>
                                </div>

                            </div>
                            <div id="modal-intro" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">

                                        <iframe title='leduc99-slider-modal-intro' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289912184?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>

                                    </div>
                                </div>
                                <div className="description-box">
                                    <h3>
                                        <nobr>WD-40<sub>®</sub></nobr> Brand Passes Kyle LeDuc’s Test
                                    </h3>
                                    <p>
                                        The products Kyle LeDuc uses must stand up to extreme racing conditions. <nobr>WD-40<sub>®</sub></nobr> Brand is up to the task.
                                    </p>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-big-blaster" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">

                                        <iframe title='leduc99-slider-modal-big-blaster' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289911830?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>

                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-20">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-big-blaster.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses WD-40 Big Blast"}} />
                                    </div>
                                    <div className="description-box-content-80">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> Big <nobr>Blast<sub>®</sub></nobr>
                                        </h3>
                                        <ul>
                                            <li>
                                                Prevents rust
                                            </li>
                                            <li>
                                                Prevents dirt and debris from sticking
                                            </li>
                                            <li>
                                                Use for wide coverage
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-electrical-contact-cleaner" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-electrical-contact-cleaner' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289911944?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-20">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-electrical-contact-cleaner.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses Electrical Contact Cleaner Spray"}} />
                                    </div>
                                    <div className="description-box-content-80">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> Electrical Contact Cleaner Spray
                                        </h3>
                                        <ul>
                                            <li>
                                                Safe for plastic, rubber and metal
                                            </li>
                                            <li>
                                                Quick drying, no messy residue
                                            </li>
                                            <li>
                                                Use for cleaning sensitive electrical equipment
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-dry-lube" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-dry-lube' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289911909?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-20">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-dry-lube.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses Dry Lube"}} />
                                    </div>
                                    <div className="description-box-content-80">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> Dry Lube PTFE Spray
                                        </h3>
                                        <ul>
                                            <li>
                                                Reduces friction and wear
                                            </li>
                                            <li>
                                                PTFE spray dries quickly
                                            </li>
                                            <li>
                                                Won’t attract dirt, dust or oil
                                            </li>
                                            <li>
                                                Use on inside of wheel where bolts to hub face
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-carb-throttle" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-carb-throttle' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289911865?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-20">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-carb-throttle.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses Carb/Throttle Body"}} />
                                    </div>
                                    <div className="description-box-content-80">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> Carb/Throttle Body &amp; Parts Cleaner
                                        </h3>
                                        <ul>
                                            <li>
                                                Blasts away tough deposits
                                            </li>
                                            <li>
                                                Improves performance at high RPMs
                                            </li>
                                            <li>
                                                Use for cleaning carburetors, throttle body &amp; metal parts
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-ez-reach" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-ez-reach' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289911979?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-30">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-ez.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses EZ REACH"}} />
                                    </div>
                                    <div className="description-box-content-70">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40</nobr> <nobr>EZ-REACH<sub>™</sub></nobr>
                                        </h3>
                                        <ul>
                                            <li>
                                                8” flexible straw
                                            </li>
                                            <li>
                                                Bends and keeps its shape
                                            </li>
                                            <li>
                                                Use to get into hard to reach places
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-spray-and-stay-gel" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-spray-and-stay-gel' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289912055?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-20">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-spray-and-stay-gel.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses Spray &amp; Stay Gel"}} />
                                    </div>
                                    <div className="description-box-content-80">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> Spray &amp; Stay Gel Lubricant
                                        </h3>
                                        <ul>
                                            <li>
                                                Stays where you spray it
                                            </li>
                                            <li>
                                                No drip, no mess
                                            </li>
                                            <li>
                                                12X longer lasting lubrication
                                            </li>
                                            <li>
                                                Use on parking brake and anywhere that needs long-lasting lubrication and rust protection
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-grease" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-grease' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289911999?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-20">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-grease.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses Grease"}} />
                                    </div>
                                    <div className="description-box-content-80">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> True <nobr>Multi-Purpose</nobr> Grease
                                        </h3>
                                        <ul>
                                            <li>
                                                Long-lasting corrosion protection
                                            </li>
                                            <li>
                                                Extends the life of equipment
                                            </li>
                                            <li>
                                                Use on CV joints
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-degreaser" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-degreaser' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289912024?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-30">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-degreaser.jpg" htmlAttributes={{alt: "Kyle LeDuc Uses Non-Aerosol Cleaner &amp; Degreaser"}} />
                                    </div>
                                    <div className="description-box-content-70">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Specialist<sub>®</sub></nobr> Industrial-Strength Cleaner &amp; Degreaser
                                        </h3>
                                        <ul>
                                            <li>
                                                Breaks down tough grease and grime
                                            </li>
                                            <li>
                                                Safe on aluminum, copper and glass
                                            </li>
                                            <li>
                                                Use to clean greasy tools and parts
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>

                            <div id="modal-mup" className="popup-modal slider mfp-hide">
                                <div className="media">
                                    <div className="embed-container">
                                        <iframe title='leduc99-slider-modal-mup' width="640" height="360" frameBorder="0" webkitallowfullscreen="" mozallowfullscreen="" allowFullScreen="" src="//player.vimeo.com/video/289912117?portrait=0&amp;amp;badge=0&amp;amp;color=ffffff" data-gtm-yt-inspected-999173_166="true"></iframe>
                                    </div>
                                </div>
                                <div className="description-box">
                                    <div className="description-box-product-30">
                                        <Imgix height={200} width={200} src="/img/leduc99/product-mup.jpg" htmlAttributes={{alt: "How Kyle LeDuc Uses WD-40® Multi-Use Product"}} />
                                    </div>
                                    <div className="description-box-content-70">
                                        <h3>
                                            How Kyle LeDuc Uses <nobr>WD-40<sub>®</sub></nobr> <nobr>Multi-Use</nobr> Product
                                        </h3>
                                        <ul>
                                            <li>
                                                Lubricate suspension points
                                            </li>
                                            <li>
                                                Loosen nuts and bolts
                                            </li>
                                            <li>
                                                Shine and protect vehicle metal
                                            </li>
                                            <li>
                                                Use on a variety of jobs
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="link-box">
                                    <a href="#" className="popup-modal-dismiss">
                                        Close
                                    </a>
                                </div>
                            </div>


                        </div>

                    </section>


                    <section className="mobile">
                        <div className="item-wrap">
                            <a href="#modal-intro" className="hotspot overlay">
                                <Imgix height={200} width={200} src="/img/leduc99/kyle-intro.png" htmlAttributes={{alt: "Kyle LeDuc Intro"}} className="kyle-thumb" />
                            </a>
                        </div>
                    </section>

                </div>
                <div id="preloader">
                    <div id="loader"></div>
                </div>

            </div>
        );
    }
}